import React from "react";
import { inject, observer } from "mobx-react";
import { convertCurrencySummaryLocation, datePicker } from "../helpers";
import { Translation } from "../Helpers/Translation";
import ApiService from "../services/api.service";

@inject("AppStore")
@observer
class PdfStep extends React.Component {
  store = this.props.AppStore;
  apiService = new ApiService();
  dates = datePicker(this.store.data.flat_name);

  async componentDidMount() {
    const { user } = await this.apiService.checkUser();
  }

  render() {
    const { store } = this.props;

    return (
      <div className="step-payment-content" id="pdf-step">
        <div className="left">
          <h2>ENTER YOUR DETAILS</h2>

          <form id="pdf_form" onSubmit={store.onPdfSubmit}>
            <div className="form">
              <div className="form-item">
                <label>
                  <Translation lang={store.page.lang} t="form_first_name" />
                </label>
                <input
                  required
                  type="text"
                  name="firstname"
                  value={store.detailsForm.firstname}
                  onChange={(e) =>
                    (store.detailsForm.firstname = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>
                  <Translation lang={store.page.lang} t="form_last_name" />
                </label>
                <input
                  required
                  type="text"
                  name="lastname"
                  value={store.detailsForm.lastname}
                  onChange={(e) =>
                    (store.detailsForm.lastname = e.target.value)
                  }
                />
              </div>
              <div className="form-item">
                <label>
                  <Translation lang={store.page.lang} t="form_email" />
                </label>
                <input
                  required
                  type="email"
                  name="email"
                  value={store.detailsForm.email}
                  onChange={(e) => (store.detailsForm.email = e.target.value)}
                />
              </div>
              <div className="form-item">
                <label>
                  <Translation lang={store.page.lang} t="form_phone" />
                </label>
                <input
                  required
                  type="text"
                  name="phone"
                  value={store.detailsForm.phone}
                  onChange={(e) => (store.detailsForm.phone = e.target.value)}
                />
              </div>
              <div className="form-item">
                <label>
                  <Translation lang={store.page.lang} t="form_boating" />
                </label>
                <input
                  type="text"
                  name="boating"
                  value={store.detailsForm.boating}
                  onChange={(e) => (store.detailsForm.boating = e.target.value)}
                />
              </div>
              <div className="form-item">
                <label>Preffered Delivery Date</label>
                <select
                  value={store.detailsForm.delivery}
                  onChange={(e) =>
                    (store.detailsForm.delivery = e.target.value)
                  }
                >
                  <option></option>
                  {this.dates.map((d, i) => (
                    <option
                      value={d.value}
                      style={d.style}
                      key={i}
                      disabled={d.disabled}
                    >
                      {d.text}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-item">
                <label>Address</label>
                <input
                  type="text"
                  name="address"
                  value={store.detailsForm.address}
                  onChange={(e) => (store.detailsForm.address = e.target.value)}
                />
              </div>
            </div>
            <div style={{ textAlign: "center", marginTop: 50 }}>
              {/* <div className="modelSelector__button">
                <a type="submit" onClick={() => { document.querySelector("#pdf_form").submit()}}>
                  <Translation t="button_pdf" lang={store.page.lang} />
                </a>
              </div> */}

              <button type="submit" className="button__clean">
                SEND PDF
              </button>
            </div>
          </form>
        </div>
        <div className="options-wrapper">
          {store.summary.model.selected_picture && (
            <img
              className="image"
              src={`${store.summary.model.selected_picture}`}
              alt=""
            />
          )}
          <div
            className="model-name"
            dangerouslySetInnerHTML={{ __html: store.data.name }}
          ></div>

          {/* <div className="standards-wrapper">
            <h3>
              <Translation lang={store.page.lang} t="summary_standards" />
            </h3>
            <div className="standards">
              <div className="standards-left">
                <pre>{store.data[store.langify("standards")]}</pre>
              </div>
              <div className="standards-right">
                <pre>{store.data[store.langify("standards_right")]}</pre>
              </div>
            </div>
          </div> */}
          <div className="upgrades-wrapper">
            <div className="option-group-title">
              <div
                className="selectedModel__subtitle--top uc"
                style={{ borderColor: `${store.data.color}` }}
              >
                ORDER
              </div>
              <div className="selectedModel__subtitle--bottom">SUMMARY</div>
            </div>
            <div className="upgrades">
              {Object.keys(store.summary.upgradeOptionsDisplay).map(
                (step, i) => (
                  <div key={i} className="">
                    <div
                      style={{
                        paddingBottom: 10,
                        paddingTop: 10,
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      <div className="upgradeoption-step">{step}</div>
                      {Object.keys(
                        store.summary.upgradeOptionsDisplay[step]
                      ).map((option_group_title, j) => (
                        <div key={j}>
                          {option_group_title !== "null" && (
                            <div
                              className="upgradeoption-option-group-title"
                              style={{ marginLeft: 35 }}
                            >
                              {option_group_title}
                            </div>
                          )}

                          {Object.keys(
                            store.summary.upgradeOptionsDisplay[step][
                              option_group_title
                            ]
                          ).map((option_group, k) => (
                            <React.Fragment key={k}>
                              <div
                                className="upgradeoption-option-group"
                                style={{
                                  marginLeft:
                                    option_group_title !== "null" ? 45 : 35,
                                }}
                              >
                                {option_group}
                              </div>

                              {store.summary.upgradeOptionsDisplay[step][
                                option_group_title
                              ][option_group].map((option, l) => (
                                <div
                                  className="upgradeoption-option option-row"
                                  key={l}
                                  style={{
                                    marginLeft:
                                      option_group_title !== "null" ? 55 : 45,
                                  }}
                                >
                                  <span
                                    className="text"
                                    style={{
                                      marginLeft: 10,
                                    }}
                                  >
                                    <div className="upgradeoption-option">
                                      {option.title}
                                    </div>
                                  </span>
                                  <span
                                    className="price animate__animated animate__fadeIn"
                                    style={{
                                      color: store.data.color,
                                      fontWeight: "bold",
                                      flex: 1,
                                      textAlign: "right",
                                    }}
                                  >
                                    {convertCurrencySummaryLocation(
                                      option.price,
                                      this.store.location
                                    )}
                                  </span>
                                </div>
                              ))}
                            </React.Fragment>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          <div className="purchase-wrapper">
            <div className="purchase-price">
              <div className="purchase-price-text">
                <Translation lang={store.page.lang} t="footer_purchase_price" />
              </div>
              <div className="purchase-price-amount">
                {convertCurrencySummaryLocation(
                  store.summary.purchase_price,
                  this.store.location
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PdfStep;
