import React from 'react';
import { appConfig } from '../config';
import { convertCurrencyLocation, sortingComparer } from '../helpers';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import CountUp from 'react-countup';
import Hint from './Hint';

@inject('AppStore')
@observer
class OptionWithImage extends React.Component {
  store = this.props.AppStore;

  render() {
    const { type, option, group, store } = this.props;

    return (
      <div
        className={`option ${
          type === 'singleImage'
            ? 'option-radio-image'
            : 'option-checkbox-image'
        } ${option.selected ? 'option-selected' : ''} ${
          option.image_top ? 'image-top' : ''
        }`}
        style={{ display: option.hidden ? 'none' : '' }}
        onClick={() => store.selectOption(option, group)}
      >
        {option.picture && (
          <div className="option-image">
            <img
              alt=""
              src={`${appConfig.api}${option.picture.url}`}
              className="animate__animated animate__fadeIn"
            />
          </div>
        )}
        <div className="option-content">
          <div className="option-title">
            {option.tooltip && (
              <Hint
                tooltipId={option.id}
                text={option.tooltip}
                url={option.tooltip_url}
              />
            )}
            <span style={{ paddingBottom: 5 }}>
              {option[store.langify('title')]}
            </span>
          </div>
          {option.description && (
            <div className="option-description">
              <pre>{option[store.langify('description')]}</pre>
            </div>
          )}
          <div className="option-price">
            {convertCurrencyLocation(option.price, this.store.location, true)}
            <span
              className={`option-selector ${option.selected && 'selected'}`}
            >
              <span
                className="tick"
                style={{ background: store.data.color }}
              ></span>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

@inject('AppStore')
@observer
class OptionWithoutImage extends React.Component {
  store = this.props.AppStore;

  render() {
    const { type, option, group, store } = this.props;

    return (
      <div
        className={`option ${
          type === 'single' ? 'option-radio' : 'option-checkbox'
        } ${option.selected ? 'option-selected' : ''}`}
        style={{ display: option.hidden ? 'none' : 'flex' }}
        onClick={() => store.selectOption(option, group)}
      >
        {option.picture && (
          <div
            className="option-image"
            style={{ borderColor: option.selected ? store.data.color : '#fff' }}
          >
            <img
              alt="foto"
              src={`${appConfig.api}${option.picture.url}`}
              className="animate__animated animate__fadeIn"
            />
          </div>
        )}
        <div style={{ flex: 1 }}>
          <div className="option-title">{option.description}</div>
          <div className="option-radio-description">{option.title}</div>
          <div className="option-price">
            {convertCurrencyLocation(option.price, this.store.location, true)}
            <span
              className={`option-selector ${option.selected && 'selected'}`}
            >
              <span
                className="tick"
                style={{ background: store.data.color }}
              ></span>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

@observer
class OptionPicker extends React.Component {
  render() {
    const { option, group, store } = this.props;

    return (
      <div
        className={`option option-picker ${
          option.selected ? 'option-selected' : ''
        }`}
      >
        {option.picture && (
          <div
            className="option-image"
            style={{ borderColor: option.selected ? store.data.color : '#fff' }}
            onClick={() => store.selectOption(option, group)}
          >
            <img
              alt=""
              src={`${appConfig.api}${option.picture.url}`}
              className="animate__animated animate__fadeIn"
            />
          </div>
        )}

        <div className="option-title">{option.title}</div>
      </div>
    );
  }
}

@inject('AppStore')
@observer
class OptionAdditional extends React.Component {
  store = this.props.AppStore;

  render() {
    const { option, group, store } = this.props;

    return (
      <div
        className={`option option-additional ${
          option.selected ? 'option-selected' : ''
        }`}
        style={{ display: option.hidden ? 'none' : '' }}
      >
        <div className="option-additional-title">
          SPECIFY {group.title} HERE
        </div>
        <div className="option-content">
          <div className="option-title">
            <input
              type="text"
              className="option-additional-text"
              value={option.specified_value}
              placeholder={`ENTER YOUR DESIRED ${group.title}`}
              onClick={(e) => {
                if (!option.selected) {
                  store.selectOption(option, group);
                }
              }}
              onChange={(e) => {
                option.specified_value = e.target.value;
                option.title = `${e.target.value}`;
              }}
            />
          </div>
          <div
            className="option-price"
            onClick={() => store.selectOption(option, group)}
          >
            {convertCurrencyLocation(option.price, this.store.location, true)}
            <span
              className={`option-selector ${option.selected && 'selected'}`}
            >
              <span
                className="tick"
                style={{ background: store.data.color }}
              ></span>
            </span>
          </div>
        </div>

        {option.description && (
          <div className="option-description">
            <pre>{option[store.langify('description')]}</pre>
          </div>
        )}
      </div>
    );
  }
}

@inject('AppStore')
@observer
class ImageStep extends React.Component {
  store = this.props.AppStore;

  componentDidMount() {
    console.log('image step');
  }

  checkRelated = (step, group) => {
    const other_options = step.option_groups.flatMap((x) =>
      toJS(x.options).filter((o) => o.option_group !== group.id)
    );

    const found = other_options.filter(
      (x) =>
        x.related &&
        x.related.option_group &&
        x.related.option_group.id === group.id
    );

    if (found.length > 0 && !found.some((x) => x.selected)) return false;

    return true;
  };

  render() {
    const { store, step, data } = this.props;

    return (
      <div className="step-image-content">
        <div className="image" style={{ position: 'relative' }}>
          {store.gallery.map(
            (item, i) =>
              store.image_mode == item.type && (
                <img
                  key={i}
                  alt="model"
                  className="animate__animated animate__fadeIn"
                  src={store.getPicture}
                  style={{
                    position: 'absolute',
                    width: '100%',
                    left: 0,
                    top: 0,
                  }}
                />
              )
          )}

          <div className="engine_info">
            <div className="engine_info__item">
              <div className="engine_info__title">Power</div>
              <div className="engine_info__value">
                <CountUp
                  className="price-text orbitron"
                  suffix={` ${store.page.power.unit}`}
                  duration={1}
                  start={0}
                  end={store.page.power.value}
                />
              </div>
            </div>
            <div className="engine_info__item">
              <div className="engine_info__title">Speed</div>
              <div className="engine_info__value">
                <CountUp
                  className="price-text orbitron"
                  suffix={` ${store.page.speed.unit}`}
                  duration={1}
                  start={0}
                  end={store.page.speed.value}
                />
              </div>
            </div>
          </div>

          <div
            className="image-changer"
            style={{ position: 'absolute', bottom: 10, right: 10 }}
          >
            <div className="image-changer-inner">
              {store.gallery.map((item, i) => (
                <a
                  key={i}
                  className={`${store.image_mode == item.type ? 'active' : ''}`}
                  href="#"
                  onClick={(e) => store.set_image_mode(e, item.type)}
                >
                  {i + 1}
                  {/* {item.type} */}
                </a>
              ))}
            </div>
          </div>
        </div>

        <div
          className="options-wrapper"
          onClick={(e) => {
            var element = e.currentTarget;
            if (
              element.scrollHeight - element.scrollTop ===
              element.clientHeight
            ) {
              store.page.nextEnabled = true;
            }
          }}
          onScroll={(e) => {
            var element = e.currentTarget;
            if (
              element.scrollHeight - element.scrollTop ===
              element.clientHeight
            ) {
              store.page.nextEnabled = true;
            }
          }}
        >
          <div
            className="model-name"
            dangerouslySetInnerHTML={{ __html: data.name }}
          ></div>

          <div className="option-groups">
            {step.option_groups
              .slice()
              .sort(sortingComparer)
              .map(
                (group, i) =>
                  this.checkRelated(step, group) && (
                    <React.Fragment key={i}>
                      {group.group_title && group.show_group_title && (
                        <div className="option-group-subtitle">
                          {group.group_title}
                        </div>
                      )}

                      <div className={`option-group ${group.type}`}>
                        {!group.hide_title && (
                          <div
                            className="option-group-title"
                            style={{ display: 'flex' }}
                          >
                            <div className="selectedModel__subtitle">
                              <div
                                className="selectedModel__subtitle--top uc"
                                style={{
                                  borderColor: `${store.data.color}`,
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <span style={{ paddingBottom: 5 }}>
                                  {group.title}
                                </span>

                                {group.tooltip && (
                                  <Hint
                                    style={{ paddingLeft: 5 }}
                                    tooltipId={group.id}
                                    text={group.tooltip}
                                    url={group.tooltip_url}
                                  />
                                )}
                              </div>
                              <div className="selectedModel__subtitle--bottom">
                                {group.required
                                  ? 'MANDATORY SELECTION'
                                  : 'SELECTION'}
                              </div>
                            </div>
                            <div className="brand-logo">
                              {group.brand && (
                                <img
                                  alt=""
                                  src={`${appConfig.api}${group.brand.url}`}
                                />
                              )}
                            </div>
                          </div>
                        )}

                        {group.description && (
                          <div
                            className="option-maindescription"
                            style={{ fontSize: 12, padding: 15 }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: group.description,
                              }}
                            ></div>
                          </div>
                        )}
                        <div
                          className="option-price"
                          style={{
                            height: 15,
                            marginTop: 0,
                            marginRight: 15,
                          }}
                        >
                          {group.options.map((option, j) => {
                            if (group.type === 'picker' && j === 0) {
                              return (
                                <span
                                  className="animate__animated animate__fadeIn"
                                  key={j}
                                >
                                  {convertCurrencyLocation(
                                    option.price,
                                    this.store.location,
                                    false
                                  )}
                                </span>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </div>
                        <div className={`options`}>
                          {group.options.map((option, j) => {
                            if (option.additional) {
                              return (
                                <OptionAdditional
                                  key={j}
                                  type={group.type}
                                  group={group}
                                  option={option}
                                  store={store}
                                />
                              );
                            } else if (
                              group.type === 'singleImage' ||
                              group.type === 'multiImage'
                            ) {
                              return (
                                <OptionWithImage
                                  key={j}
                                  type={group.type}
                                  group={group}
                                  option={option}
                                  store={store}
                                />
                              );
                            } else if (group.type === 'picker') {
                              return (
                                <React.Fragment key={j}>
                                  <OptionPicker
                                    type={group.type}
                                    group={group}
                                    option={option}
                                    store={store}
                                  />
                                </React.Fragment>
                              );
                            } else {
                              return (
                                <OptionWithoutImage
                                  key={j}
                                  type={group.type}
                                  group={group}
                                  option={option}
                                  store={store}
                                />
                              );
                            }
                          })}
                        </div>
                      </div>
                    </React.Fragment>
                  )
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default ImageStep;
