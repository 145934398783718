import React from "react";
import ApiService from "../../services/api.service";
import { Loading } from "../../Components/Loading";
import "./assets/bootstrap-grid.css";
import "./assets/account.css";
import Nav from "./components/Nav";
import { DashboardCard } from "./components/DashboarCard";
import { Header } from "./components/Header";

const AccountLogin = () => {
  const apiService = new ApiService();
  const [user, setUser] = React.useState({});

  const [loading, setLoading] = React.useState(true);

  const init = async () => {
    const user_data = await apiService.checkUser();

    if (!user_data) {
      window.location.href = "/account/login";
    }

    setUser(user_data.user);
    setLoading(false);
  };

  React.useEffect(() => {
    init();
  }, []);

  if (loading) return <Loading />;

  return (
    <div className="account">
      <Header user={user} />

      <div
        className="wrapper"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
        }}
      >
        <Nav />
        <div className="account-content">
          <div className="account-content-inner">
            <div className="container">
              <div className="row" style={{ marginTop: 50 }}>
                <div className="col">
                  <div className="dashboard-header">{user.fullname}</div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <DashboardCard
                    title="limitlessseas.com"
                    href="https://limitlessseas.com"
                    background="dashboard_limitless.png"
                  />
                </div>
                <div className="col-md-4 col-sm-6">
                  <DashboardCard
                    title="XLV"
                    href={`https://limitlessseas.com/xlv/`}
                    background="dashboard_xlv.png"
                  />
                </div>
                <div className="col-md-4 col-sm-6">
                  <DashboardCard
                    title="LIV"
                    href={`https://limitlessseas.com/liv/`}
                    background="dashboard_liv.png"
                  />
                </div>
                <div className="col-md-4 col-sm-6">
                  <DashboardCard
                    title="Configurator"
                    href={`/${user.location ? user.location.name : "fl"}`}
                    background="dashboard_configurator.png"
                  />
                </div>
                <div className="col-md-4 col-sm-6">
                  <DashboardCard
                    title="Boutique"
                    // href="https://limitlessseas.com/boutique"
                    href="#"
                    disabled
                    background="dashboard_boutique.png"
                  />
                </div>
                <div className="col-md-4 col-sm-6">
                  <DashboardCard
                    title="News"
                    href="https://limitlessseas.com/news"
                    background="dashboard_news.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="account-footer">
        Trademark™️ &amp; Copyright®️ 2022 Limitless Seas Inc. All rights
        reserved.
      </div>
    </div>
  );
};

export default AccountLogin;
