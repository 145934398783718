import axios from 'axios';
import { appConfig } from '../config';

export default class ApiService {
  async me() {
    const user = await this.checkUser();

    if (user) {
      const result = await axios.get(`${appConfig.api}/account/me`, {
        headers: {
          Authorization: 'Bearer ' + user.jwt,
        },
      });

      return result.data;
    }
    return null;
  }

  async getMail(token) {
    try {
      const result = await axios.get(`${appConfig.api}/account/get-token`, {
        params: { token },
      });

      return result.data;
    } catch (e) {
      return null;
    }
  }

  async getLocations() {
    try {
      const result = await axios.get(`${appConfig.api}/locations`);
      return result.data;
    } catch {
      return [];
    }
  }

  async getBuilds() {
    const user = await this.checkUser();

    if (user) {
      const result = await axios.get(`${appConfig.api}/account/builds`, {
        headers: {
          Authorization: 'Bearer ' + user.jwt,
        },
      });

      return result.data;
    }
  }

  async update(data) {
    const user = await this.checkUser();

    if (user) {
      const result = await axios.post(`${appConfig.api}/account/update`, data, {
        headers: {
          Authorization: 'Bearer ' + user.jwt,
        },
      });

      let localUser = await this.getUser();

      localUser.user.location = result.data.location;

      await this.setUser(localUser);

      return result.data;
    }

    return null;
  }

  async login(email, password) {
    try {
      const result = await axios.post(`${appConfig.api}/auth/local`, {
        identifier: email,
        password: password,
      });

      localStorage.setItem('configurator:user', JSON.stringify(result.data));

      return result.data;
    } catch (e) {
      return null;
    }
  }

  async register(name, email, phone, region) {
    try {
      const result = await axios.post(`${appConfig.api}/account`, {
        name,
        email,
        phone,
        region,
      });

      return result.data;
    } catch (e) {
      return null;
    }
  }

  async forgot_password(email) {
    try {
      const result = await axios.post(
        `${appConfig.api}/account/forgot-password`,
        {
          email,
        }
      );

      return true;
    } catch (e) {
      return true;
    }
  }

  async forgot_password_info(token) {
    try {
      const result = await axios.get(
        `${appConfig.api}/account/get-forgot-token`,
        {
          params: { token },
        }
      );

      return result.data;
    } catch (e) {
      return '';
    }
  }

  async forgot_password_complete(token, password) {
    try {
      const result = await axios.post(
        `${appConfig.api}/my-limitless/users/forgot-password-complete`,
        {
          token,
          password,
        }
      );

      return true;
    } catch (e) {
      return false;
    }
  }

  async registration_complete(token, password) {
    try {
      const result = await axios.post(
        `${appConfig.api}/my-limitless/users/complete-registration`,
        {
          token,
          password,
        }
      );

      return result.data;
    } catch (e) {
      return false;
    }
  }

  async getUser() {
    const user = localStorage.getItem('configurator:user');

    if (user) {
      return JSON.parse(user);
    }

    return {};
  }

  async setUser(data) {
    const result = JSON.stringify(data);
    localStorage.setItem('configurator:user', result);
  }

  async checkUser() {
    // check token
    // check api
    const user = localStorage.getItem('configurator:user');

    if (user) {
      // check user
      return JSON.parse(user);
    } else {
      return false;
    }
  }

  async logout() {
    localStorage.removeItem('configurator:user');
  }

  async getYacht() {
    const auth = await this.getUser();

    const result = await axios.post(
      `${appConfig.api}/my-limitless/yacht`,
      null,
      {
        headers: {
          Authorization: `Bearer ${auth.jwt}`,
        },
      }
    );

    return result.data;
  }

  async createServiceRequest(data) {
    const auth = await this.getUser();

    const result = await axios.post(
      `${appConfig.api}/my-limitless/service-request`,
      data,
      {
        headers: {
          Authorization: `Bearer ${auth.jwt}`,
        },
      }
    );

    return result.data;
  }
}
