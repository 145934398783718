import React from "react";
import { inject, observer } from "mobx-react";
import { appConfig } from "../config";
import { Loading } from "../Components/Loading";
import ApiService from "../services/api.service";
import "../app.css";
import { Redirect } from "react-router-dom";

@inject("AppStore")
@observer
class Select extends React.Component {
  store = this.props.AppStore;
  apiService = new ApiService();

  async componentDidMount() {
    const user = await this.apiService.checkUser();
    if (!user) {
      window.location.href = "/account/login";
    }

    this.store.loadConfig();
    this.store.getLocations();
  }

  render() {
    const { page } = this.store;

    if (page.loading) return <Loading />;

    return <Redirect to={`/account`} />;

    // return (
    //   <div className="app">
    //     <div className="header">
    //       <div className="logo-wrapper">
    //         <a href={`${appConfig.locale.url}`}>
    //           {this.store.configuration && (
    //             <img
    //               src={appConfig.locale.api + this.store.configuration.logo.url}
    //               className="logo"
    //               alt="logo"
    //             />
    //           )}
    //         </a>
    //       </div>
    //     </div>
    //     <div className={`content ${this.store.selectedModel && "open"}`}>
    //       <div className="locations" style={{ flex: 1 }}>
    //         <div
    //           className="location_title"
    //           style={{
    //             marginTop: "50px",
    //             marginBottom: "25px",
    //             textAlign: "center",
    //             fontSize: "25px",
    //             fontWeight: "bold",
    //           }}
    //         >
    //           SELECT YOUR REGION
    //         </div>

    //         <div className="items" style={{ textAlign: "center" }}>
    //           <select
    //             style={{ width: "300px", padding: "10px 20px" }}
    //             onChange={(e) => {
    //               if (e.target.value) window.location = "/" + e.target.value;
    //             }}
    //           >
    //             <option>Select a Region</option>
    //             {this.store.locations.map((l, i) => (
    //               <option key={i} value={l.name}>
    //                 {l.displayname}
    //               </option>
    //             ))}
    //           </select>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="footer modelSelector__footer">
    //       <div className="modelSelector__footer--left">
    //         <div style={{ fontSize: 12, paddingRight: 10, paddingLeft: 10 }}>
    //           *Manufacturer’s Suggested Retail Price (MSRP), excludes options,
    //           taxes, title and registration processing.
    //         </div>
    //       </div>
    //       <div className="modelSelector__footer--right">
    //         {this.store.selectedModel && (
    //           <div className="modelSelector__cleanbutton">
    //             <a
    //               href={`/${this.store.location_name || "east-fl"}/build/${
    //                 this.store.selectedModel.slug
    //               }`}
    //             >
    //               NEXT
    //             </a>
    //           </div>
    //         )}
    //       </div>
    //     </div>
    //   </div>
    // );
  }
}

export default Select;
