import React from "react";
import { inject, observer } from "mobx-react";
import { appConfig } from "../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { convertCurrencyLocation } from "../helpers";
import { Loading } from "../Components/Loading";
import { AiOutlineClose } from "react-icons/ai";
import "../app.css";
import { Link } from "react-router-dom";
import ApiService from "../services/api.service";

@inject("AppStore")
@observer
class Model extends React.Component {
  store = this.props.AppStore;
  apiService = new ApiService();

  async componentDidMount() {
    const user = await this.apiService.checkUser();
    if (!user) {
      window.location.href = "/account/login";
    }
  }

  render() {
    return (
      <div
        className={`modelSelector__item animate__animated animate__zoomIn ${
          this.props.store.selectedModel &&
          this.props.store.selectedModel.id === this.props.model.id &&
          "selected"
        }`}
        onClick={() => {
          this.props.store.selectModel(this.props.model);

          // setTimeout(() => {
          //   SmoothScrolling.scrollTo("content");
          // }, 400);
        }}
      >
        <div className="modelSelector__image">
          <img
            alt="cover"
            src={appConfig.api + this.props.model.cover.url}
            style={{ borderColor: this.props.model.color }}
          />
        </div>
        <div className="modelSelector__content">
          <div className="modelSelector__name">
            <span
              dangerouslySetInnerHTML={{ __html: this.props.model.name }}
            ></span>
          </div>
          <div className="modelSelector__slogan">
            <span
              dangerouslySetInnerHTML={{ __html: this.props.model.slogan }}
            ></span>
          </div>

          <div className="modelSelector__length">
            <div className="modelSelector__content_title">Length</div>
            <div>
              {this.props.model.standard_spec &&
                this.props.model.standard_spec.length_m}
            </div>
          </div>
          <div className="modelSelector__price">
            <div className="modelSelector__content_title">From*</div>
            <div>
              {convertCurrencyLocation(
                this.props.model.starting_from,
                this.store.location,
                false
              )}
            </div>
          </div>
          <div className="modelSelector__cta">
            CONFIGURE YOUR{" "}
            <span
              dangerouslySetInnerHTML={{ __html: this.props.model.name }}
            ></span>
          </div>
        </div>
      </div>
    );
  }
}

@observer
class Tabs extends React.Component {
  state = {
    selected: 0,
  };

  componentDidMount() {
    // console.log(this.props.items);
  }

  selectTab(i) {
    if (i === this.state.selected) {
      this.setState({ selected: -1 });
    } else {
      this.setState({ selected: i });
    }
  }

  render() {
    const { model } = this.props;

    if (!model) return <React.Fragment />;

    return (
      <div className="selectedModel__tabs">
        {model.tabs &&
          model.tabs.map((x, i) => (
            <div
              className={`selectedModel__tabs--item ${
                this.state.selected === i ? "selected" : ""
              }`}
              key={i}
            >
              <div
                className="selectedModel__tabs--title"
                onClick={() => this.selectTab(i)}
              >
                {" "}
                {this.state.selected === i ? (
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="tabs-icon"
                    color={model.color}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="tabs-icon"
                    color={model.color}
                  />
                )}
                {x[0]}
              </div>
              <div
                className={`selectedModel__tabs--content ${
                  this.state.selected === i
                    ? "animate__animated animate__fadeIn"
                    : ""
                }`}
              >
                <pre dangerouslySetInnerHTML={{ __html: x[1] }} />
              </div>
            </div>
          ))}
      </div>
    );
  }
}

@inject("AppStore")
@observer
class Select extends React.Component {
  store = this.props.AppStore;

  componentDidMount() {
    const { location } = this.props.match.params;

    this.store.getLocation(location);
    this.store.loadConfig();
    this.store.getModels();
  }

  render() {
    const { page } = this.store;

    if (page.loading) return <Loading />;

    return (
      <div className="app">
        <div className="header">
          <div className="logo-wrapper">
            <a
              href={`${appConfig.locale.url}/${this.store.location_name}`}
              style={{
                display: "flex",
                alignItems: "center",
                color: "#000",
                textDecoration: "none",
                fontSize: 12,
                fontWeight: "bold",
              }}
            >
              {this.store.configuration && (
                <img
                  alt="logo"
                  src={appConfig.locale.api + this.store.configuration.logo.url}
                  className="logo"
                />
              )}
              <span>
                {this.store.location && this.store.location.displayname}
              </span>
            </a>
          </div>
          {this.store.user ? (
            <div className="header-user">
              <Link to="/account">
                <span>
                  <svg
                    width={20}
                    height={20}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50 50"
                  >
                    <path d="M25 1C11.767 1 1 11.767 1 25c0 7.091 3.094 13.472 8 17.869v.017l.348.3c.061.053.128.097.19.149a24.496 24.496 0 0 0 3.189 2.279c.085.051.172.099.257.148.557.324 1.126.629 1.71.908l.018.008a23.838 23.838 0 0 0 3.915 1.456l.075.021c.641.175 1.293.322 1.954.443l.185.033a24.17 24.17 0 0 0 1.939.262c.075.007.15.011.224.017.659.055 1.323.09 1.996.09s1.337-.035 1.996-.09c.075-.006.15-.01.224-.017.655-.06 1.301-.15 1.939-.262l.185-.033a23.451 23.451 0 0 0 1.954-.443l.075-.021a23.838 23.838 0 0 0 3.915-1.456l.018-.008a24.261 24.261 0 0 0 1.71-.908c.086-.05.172-.097.257-.148a24.123 24.123 0 0 0 1.487-.968c.124-.087.248-.174.371-.264.456-.334.9-.683 1.331-1.047.062-.052.129-.096.19-.149l.348-.3v-.017c4.906-4.398 8-10.778 8-17.869C49 11.767 38.233 1 25 1zm0 24c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8zm3 2c6.065 0 11 4.935 11 11v3.958c-.042.035-.086.067-.128.102-.395.321-.8.626-1.214.918-.092.065-.182.132-.274.195-.447.305-.906.591-1.373.862l-.257.148a21.799 21.799 0 0 1-6.871 2.468l-.171.031a22.27 22.27 0 0 1-1.715.225c-.079.007-.159.012-.239.018-.583.045-1.169.075-1.758.075s-1.175-.03-1.758-.077l-.239-.018a21.789 21.789 0 0 1-1.886-.256 22.013 22.013 0 0 1-5.212-1.626l-.161-.073a21.799 21.799 0 0 1-1.755-.917c-.467-.27-.926-.557-1.373-.862-.093-.063-.183-.13-.274-.195a21.826 21.826 0 0 1-1.214-.918c-.042-.034-.086-.067-.128-.102V38c0-6.065 4.935-11 11-11h6zm13 13.076V38c0-6.271-4.464-11.519-10.38-12.735A9.996 9.996 0 0 0 35 17c0-5.514-4.486-10-10-10s-10 4.486-10 10a9.996 9.996 0 0 0 4.38 8.265C13.464 26.481 9 31.729 9 38v2.076C5.284 36.135 3 30.831 3 25 3 12.869 12.869 3 25 3s22 9.869 22 22c0 5.831-2.284 11.135-6 15.076z" />
                  </svg>
                </span>
                {this.store.user.user.fullname}
              </Link>
            </div>
          ) : (
            <div className="header-user">
              <Link to="/account/login">Account</Link>
            </div>
          )}
        </div>
        <div className={`content ${this.store.selectedModel && "open"}`}>
          <div
            className="modelSelector__left"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <div>
              <div className="modelSelector__title">
                SELECT YOUR <br className="m-only" />{" "}
                <strong>{appConfig.locale.name}</strong>
              </div>
              <div
                className="modelSelector__titleSlogan"
                dangerouslySetInnerHTML={{
                  __html: this.store.configuration.slogan,
                }}
              ></div>
            </div>
            <div className="modelSelector__items">
              {this.store.list.map((x) => (
                <Model key={x.id} model={x} store={this.store} />
              ))}
            </div>
          </div>

          {this.store.selectedModel && (
            <div
              id="content"
              className="modelSelector__rightBackdrop"
              onClick={(e) => {
                this.store.selectedModel = undefined;
              }}
            >
              <div
                className={`modelSelector__right ${
                  this.store.selectAnimate
                    ? "animate__animated animate__fadeInRight"
                    : ""
                }`}
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  className="modelSelector__right--close"
                  onClick={(e) => {
                    this.store.selectedModel = undefined;
                  }}
                >
                  <AiOutlineClose fontSize="32" />
                </div>
                <div
                  className="selectedModel__title"
                  style={{ textAlign: "center" }}
                  dangerouslySetInnerHTML={{
                    __html: this.store.selectedModel.name,
                  }}
                ></div>
                <div className="selectedModel__tech">
                  <div className="selectedModel__subtitle">
                    <div
                      className="selectedModel__subtitle--top"
                      style={{
                        borderColor: `${this.store.selectedModel.color}`,
                      }}
                    >
                      TECHNICAL
                    </div>
                    <div className="selectedModel__subtitle--bottom">
                      SPECIFICATIONS
                    </div>
                  </div>
                  <div className="selectedModel__specs">
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">Length</div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.length_m}
                      </div>
                    </div>
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">Beam</div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.beam}
                      </div>
                    </div>
                    {this.store.selectedModel.standard_spec &&
                      this.store.selectedModel.standard_spec.beam_opened && (
                        <div className="selectedModel__spec">
                          <div className="selectedModel__spec--key">
                            Beam with open Side Teracces
                          </div>
                          <div className="selectedModel__spec--text">
                            {this.store.selectedModel.standard_spec &&
                              this.store.selectedModel.standard_spec
                                .beam_opened}
                          </div>
                        </div>
                      )}
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">Draft</div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.draft}
                      </div>
                    </div>
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">
                        Displacement
                      </div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.displacement}
                      </div>
                    </div>
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">Passengers</div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.passengers}
                      </div>
                    </div>
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">Berths</div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.berths}
                      </div>
                    </div>
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">Fuel Cap.</div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.fuel}
                      </div>
                    </div>
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">Range</div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.range}
                      </div>
                    </div>
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">
                        Fresh Water Cap.
                      </div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.water}
                      </div>
                    </div>
                    <div className="selectedModel__spec">
                      <div className="selectedModel__spec--key">
                        Classification
                      </div>
                      <div className="selectedModel__spec--text">
                        {this.store.selectedModel.standard_spec &&
                          this.store.selectedModel.standard_spec.classification}
                      </div>
                    </div>
                  </div>

                  <div className="selectedModel__engines">
                    <div className="selectedModel__subtitle">
                      <div
                        className="selectedModel__subtitle--top"
                        style={{
                          borderColor: `${this.store.selectedModel.color}`,
                        }}
                      >
                        ENGINE
                      </div>
                      <div className="selectedModel__subtitle--bottom">
                        TYPES
                      </div>
                    </div>

                    {this.store.selectedModel.standard_spec &&
                      this.store.selectedModel.standard_spec.engine1_type && (
                        <div className="selectedModel__engine">
                          <table>
                            <tbody>
                              <tr>
                                <td>Engine Type</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine1_type
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Propulsion</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine1_propulsion
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Fuel Type</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine1_fueltype
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Engines</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine1_engine
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Speed</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine1_speed
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}

                    {this.store.selectedModel.standard_spec &&
                      this.store.selectedModel.standard_spec.engine2_type && (
                        <div className="selectedModel__engine">
                          <table>
                            <tbody>
                              <tr>
                                <td>Engine Type</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine2_type
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Propulsion</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine2_propulsion
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Fuel Type</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine2_fueltype
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Engines</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine2_engine
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Speed</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine2_speed
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}

                    {this.store.selectedModel.standard_spec &&
                      this.store.selectedModel.standard_spec.engine3_type && (
                        <div className="selectedModel__engine">
                          <table>
                            <tbody>
                              <tr>
                                <td>Engine Type</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine3_type
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Propulsion</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine3_propulsion
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Fuel Type</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine3_fueltype
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Engines</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine3_engine
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Speed</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine3_speed
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}

                    {this.store.selectedModel.standard_spec &&
                      this.store.selectedModel.standard_spec.engine4_type && (
                        <div className="selectedModel__engine">
                          <table>
                            <tbody>
                              <tr>
                                <td>Engine Type</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine4_type
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Propulsion</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine4_propulsion
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Fuel Type</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine4_fueltype
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Engines</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine4_engine
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>Speed</td>
                                <td>
                                  {
                                    this.store.selectedModel.standard_spec
                                      .engine4_speed
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                  </div>
                </div>

                {this.store.selectedModel.extra_features && (
                  <div className="selectedModel__standard">
                    <div className="selectedModel__subtitle">
                      <div
                        className="selectedModel__subtitle--top"
                        style={{
                          borderColor: `${this.store.selectedModel.color}`,
                        }}
                      >
                        EXTRA INCLUDED
                      </div>
                      <div className="selectedModel__subtitle--bottom">
                        STANDARD FEATURES
                      </div>
                    </div>
                    <div
                      className="selectedModel__standardspecs"
                      style={{ paddingTop: 0 }}
                    >
                      <pre
                        dangerouslySetInnerHTML={{
                          __html: this.store.selectedModel.extra_features,
                        }}
                      ></pre>
                    </div>
                  </div>
                )}

                <div className="selectedModel__standard">
                  <div className="selectedModel__subtitle">
                    <div
                      className="selectedModel__subtitle--top"
                      style={{
                        borderColor: `${this.store.selectedModel.color}`,
                      }}
                    >
                      STANDARD
                    </div>
                    <div className="selectedModel__subtitle--bottom">
                      SPECIFICATIONS
                    </div>
                  </div>
                  <div className="selectedModel__standardspecs">
                    <Tabs model={this.store.selectedModel} />
                    <div
                      style={{ fontSize: 12, marginTop: 35, marginBottom: 100 }}
                    >
                      Specifications are based on available information at the
                      time. Actual specifications may vary.
                      {appConfig.locale.name} reserves the right to change
                      product line specifications, equipment, color scheme and
                      pricing, or to discontinue models at any time without
                      notice or obligation.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="footer modelSelector__footer">
          <div className="modelSelector__footer--left">
            <div style={{ fontSize: 12, paddingRight: 10, paddingLeft: 10 }}>
              *Manufacturer’s Suggested Retail Price (MSRP), excludes options,
              taxes, title and registration processing.
            </div>
          </div>
          <div className="modelSelector__footer--right">
            <div className="button-wrapper">
              {this.store.user && this.store.user.user.factory_order && (
                <div
                  className="modelSelector__cleanbutton"
                  style={{ marginRight: 15 }}
                >
                  <a href={`/${this.store.location_name || "east-fl"}/builds`}>
                    BUILDS
                  </a>
                </div>
              )}

              {this.store.selectedModel && (
                <div className="modelSelector__cleanbutton">
                  <a
                    href={`/${this.store.location_name || "east-fl"}/build/${
                      this.store.selectedModel.slug
                    }`}
                  >
                    NEXT
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Select;
