import React from "react";
import logo from "./assets/logo.svg";
import background from "./assets/bg.jpeg";
import ApiService from "../../services/api.service";
import { Loading } from "../../Components/Loading";
import { useParams } from "react-router-dom";
import "./assets/bootstrap-grid.css";
import "./assets/account.css";

export const AccountComplete = () => {
  const { token } = useParams();
  const apiService = new ApiService();
  const [password, setPassword] = React.useState("");
  const [password2, setPassword2] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");

  const init = async () => {
    const result = await apiService.getMail(token);
    setEmail(result.email);
  };

  React.useEffect(() => {
    init();
  }, []);

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    if (!password || !password2) {
      setError("Please provide a password");
      setLoading(false);
      return;
    }

    if (password !== password2) {
      setError("Passwords are different");
      setLoading(false);
      return;
    }

    const result = await apiService.registration_complete(token, password);

    if (result) {
      window.location.href = `/account/login?u=` + result.email;
    } else {
      setLoading(false);
      setError("Invalid request. Please contact to Limitless sales team.");
    }
  };

  if (loading) return <Loading />;

  return (
    <div className="account">
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="col-2">
              <div className="logo">
                <img src={logo} />
              </div>
            </div>
            <div className="col-10"></div>
          </div>
        </div>
      </div>
      <div
        className="wrapper"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <form onSubmit={login}>
          <div className="login-form">
            <div className="login-title">
              <div
                className="login-title-sm font-alt"
                style={{ marginBottom: 15 }}
              >
                {email}
              </div>
              <div className="login-title-sm font-alt">Welcome to</div>
              <div className="login-title-md font-alt">
                Limitless Seas Account
              </div>
              <div
                className="login-title-sm font-alt"
                style={{ marginBottom: 15 }}
              >
                Please create a password for the Limitless Seas Account login.
              </div>
            </div>
            <div className="login-content">
              <div className="login-line">
                <input
                  type="password"
                  placeholder="New Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="login-line">
                <input
                  type="password"
                  placeholder="New Password Again"
                  onChange={(e) => setPassword2(e.target.value)}
                />
              </div>
              <div className="login-line" style={{ marginTop: 15 }}>
                <button type="submit" className="button-dark" onClick={login}>
                  Complete Registiration
                </button>
              </div>
              {error && (
                <div
                  className="error"
                  style={{
                    backgroundColor: "indianred",
                    textAlign: "center",
                    marginTop: 10,
                    fontSize: 12,
                    color: "#fff",
                  }}
                >
                  {error}
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
      <div className="account-footer">
        Trademark™️ &amp; Copyright®️ 2022 Limitless Seas Inc. All rights
        reserved.
      </div>
    </div>
  );
};
