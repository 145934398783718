import { Provider } from 'mobx-react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Account from './Pages/account/Index';
import AccountLogin from './Pages/account/Login';
import AccountRegister from './Pages/account/Register';
import App from './Pages/App';
import Broker from './Pages/Broker';
import Builds from './Pages/Builds';
import Location from './Pages/Location';
import Select from './Pages/Select';
import * as serviceWorker from './serviceWorker';
import { Store } from './store';

import { AccountComplete } from './Pages/account/Complete';
import AccountConfigurations from './Pages/account/Configurations';
import AccountForgotPassword from './Pages/account/ForgotPassword';
import AccountProfile from './Pages/account/Profile';
import ManageUserDetail from './Pages/manage/UserDetail';
import ManageUsers from './Pages/manage/Users';
import React from 'react';

const appStore = new Store();

ReactDOM.render(
  <Provider AppStore={appStore}>
    <Router>
      <Switch>
        <Route path="/account" exact component={Account} />
        <Route path="/account/profile" exact component={AccountProfile} />
        <Route path="/account/login" exact component={AccountLogin} />
        <Route path="/account/register" exact component={AccountRegister} />
        <Route
          path="/account/forgot-password/:token"
          exact
          component={AccountForgotPassword}
        />
        <Route
          path="/account/complete/:token"
          exact
          component={AccountComplete}
        />
        <Route
          path="/account/configurations"
          exact
          component={AccountConfigurations}
        />

        <Route path="/manage/users" exact component={ManageUsers} />
        <Route path="/manage/users/:id" exact component={ManageUserDetail} />

        <Route path="/:location/build/:slug/:number" exact component={App} />
        <Route path="/:location/build/:slug" exact component={App} />
        <Route path="/:location/builds" exact component={Builds} />
        <Route path="/:location/account" exact component={Broker} />
        <Route path="/:location" exact component={Select} />

        <Route path="/" exact component={Location} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
